import {EnvironmentInterface} from './environment-interface';

export const environment: EnvironmentInterface = {
  production: false,
  ocpApimSubscriptionKey: '748879d68d744b1da0dfcff5284c2d91',
  backendUrl: 'https://dev.apif.iasp.tgscloud.net/catw/v0',
  instrumentationKey: 'd99a848b-e3cf-4439-8ed7-6b45bff13573',
  authentication: {
    clientId: 'c0c3b0dd-e06d-4035-8c53-59b8bbd84b83',
    authority: 'https://login.microsoftonline.com/329e91b0-e21f-48fb-a071-456717ecc28e',
    redirectUri: '/',
    backend: {
      uri: 'https://dev.apif.iasp.tgscloud.net/catw/v0',
      scopes: ['api://e14adc4f-4e09-4cf1-a9b6-47228d98dfca/User.Read'],
    },
  }
};
