import { Component, Input, OnChanges } from '@angular/core';
import { LEGEND, MARKAREA, MARKLINE, TITLE, TOOLTIP, XAXIS, YAXIS } from '../../constants/forecast-graph';
import { EChartsOption } from 'echarts';
import { COLORS } from '../../constants/styles';
import { ForecastDataPeriodsService } from 'src/app/forecast/services/forecast-data-periods.service';
import { tooltipFormatter } from './formatters/tooltip-formatter';
import { IForecastDisplayPreference } from 'src/app/types/forecast-display-preference';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-ui-graph',
  templateUrl: './ui-graph.component.html',
})
export class UiGraphComponent implements OnChanges {
  @Input() graphType = '';
  @Input() xAxisTitle = '';
  @Input() yAxisTitle = '';
  @Input() xValues: number[] = [];
  @Input() forecastedValues: number[] = [];
  @Input() testRunsValues: number[] = [];
  @Input() startRunDate?: IForecastDisplayPreference | null;

  public options?: EChartsOption;
  private markLines: any;
  constructor(private readonly forecastDataPeriodsService: ForecastDataPeriodsService, private readonly activatedRoute: ActivatedRoute) {}
  private echartDataFrom(xValues: number[], yValues: number[]) {
    return xValues?.map((v, index) => [v, yValues[index]]);
  }

  ngOnChanges(): void {    
    const lineSeriesData = this.echartDataFrom(this.xValues, this.forecastedValues);
    const scatteredSeriesData = this.echartDataFrom(this.xValues, this.testRunsValues);
    this.markLines = this.forecastDataPeriodsService.getPeriodMarkers()?.graphPeriodMarks;

    this.options = {
      title: {
        ...TITLE,
        text: this.yAxisTitle,
      },
      tooltip: {
        ...TOOLTIP,
        formatter: tooltipFormatter(this.startRunDate, this.forecastDataPeriodsService.getPeriodMarkers()?.graphAreaMark, this.markLines?.firstMarkArea, this.markLines?.lastMarkArea),
      },
      legend: {
        ...LEGEND,
      },
      xAxis: {
        ...XAXIS,
        name: this.xAxisTitle,
        nameLocation: 'middle',
        nameTextStyle: {
          padding: [15, 0, 0, 0],
          fontWeight: 'bold',
          color: '#000',
        },
      },
      yAxis: {
        ...YAXIS,
        min: this.getYaxisMin(),        
      },
      series: [
        {
          type: 'line',
          data: lineSeriesData,
          color: COLORS.lineGraph,
          symbol: 'none',
          name: 'Forecast',

          markLine: {
            ...MARKLINE,
            data: this.markLines?.firstMarkArea > 0 ? this.markLines?.markline : [],
          },
          markArea: {
            ...MARKAREA,
            data: this.forecastDataPeriodsService.getPeriodMarkers()?.graphAreaMark,
          },
        },
        {
          type: 'scatter',
          symbol: 'diamond',
          color: COLORS.unitData,
          data: scatteredSeriesData,
          name: 'Unit data',
        },
      ],
    };
  }

  getYaxisMin() {
    const refineryCode = this.activatedRoute.snapshot.params['refineryCode']; 
    return () => this.graphType === 'WABT' && refineryCode === 'LEU' ? 300 : 0;
  }
}
