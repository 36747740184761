import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { IForecastDisplayPreference } from 'src/app/types/forecast-display-preference';
import { ForecastToDisplay, IGraphData } from 'src/app/types/forecast-results';
import { GraphDisplayService } from '../services/graph-display.service';

@Component({
  selector: 'app-forecast-graph',
  templateUrl: './forecast-graph.component.html',
  styleUrls: ['./forecast-graph.component.scss'],
})
export class ForecastGraphComponent implements OnChanges {
  @Input() predictionResults!: IGraphData;
  @Input() index = 0;
  @Input()
  startRunDate?: IForecastDisplayPreference | null;
  
  public selectedForecast!: ForecastToDisplay;
  constructor(private graphDisplayService: GraphDisplayService) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes) {
      const displayOrder = this.graphDisplayService.getGraphDisplayOrder()[this.index];
      if (this.predictionResults) this.selectedForecast = this.predictionResults.forecastToDisplay[displayOrder];
    }
  }
  selectedGraph() {
    const graphNewOrderDisplay = this.predictionResults.forecastToDisplay.indexOf(this.selectedForecast);
    this.graphDisplayService.setGraphDisplayOrder(this.index, graphNewOrderDisplay);
  }
}
