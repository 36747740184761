import { TooltipComponentFormatterCallbackParams } from 'echarts';
import * as moment from 'moment';

/**
 * @exports
 * @function tooltipFormatter
 * @param markLines
 * @returns graph tooltip
 */
export function tooltipFormatter(startRunDate:any, markLines: any, firstMarkArea: number, lastMarkArea: number): (params: TooltipComponentFormatterCallbackParams) => string {
  return (params) => {
    let tooltipHtml = '';

    if (Array.isArray(params)) {
      if (Array.isArray(params[0].value)) {
        const dayValue = Number(params[0].value[0]);
        const periodValue = findIntervalIndex(markLines, dayValue);
        tooltipHtml += addHtmlPeriod(periodValue, dayValue, firstMarkArea, lastMarkArea);
        tooltipHtml += addHtmlDate(startRunDate, dayValue);
        tooltipHtml += `<div style="display: flex; justify-content: space-between; align-items: center"><span style="margin-right: 1rem;margin-left: 1rem">Day</span> <span style="font-weight: bold"></span> <span style="font-weight: bold">${dayValue}</span></div>`;
      }
      params.forEach((param) => {
        if (Array.isArray(param.value)) {
          const numValue = param.value[1] ? Number(param.value[1]).toFixed(3) : '-';
          tooltipHtml += `<div style="display: flex; justify-content: space-between; align-items: center"><span style="margin-right: 1rem">${param.marker} ${param.seriesName}</span> <span style="font-weight: bold"></span> <span style="font-weight: bold">${numValue}</span></div>`;
        }
      });
    }

    return tooltipHtml;
  };
}

/**
 * @function findIntervalIndex
 * @param arr periods (start , end)
 * @param dos day on stream
 * @returns the index of dos in the periods
 */

export function findIntervalIndex(arr: { xAxis: number }[][], dos: number): number {
  for (let i = 0; i < arr.length; i++) {
    const interval = arr[i];
    if (dos >= interval[0]?.xAxis && dos <= interval[1]?.xAxis) {
      return i + 1;
    }
  }
  return -1;
}

export function addHtmlPeriod(periodValue: number, dayValue: number, firstMarkArea: number, lastMarkArea: number): string {
  if (periodValue == -1 && dayValue >= firstMarkArea && dayValue < lastMarkArea)
    return '<div style="display: flex; justify-content: space-between; align-items: center"><span style="margin: 5px auto; font-weight: bold">Catalyst ChangeOut</span></div>';

  if (periodValue > 0)
    return `<div style="display: flex; justify-content: space-between; align-items: center"><span style="margin: 5px auto; font-weight: bold">Period ${periodValue}</span></div>`;
  return '';
}

export function addHtmlDate(startRunDate:any, dos:number) {  
  const dateBeforeConv = startRunDate ? moment(new Date(startRunDate.start_of_run)).add(dos, 'd') : null;
  if (dateBeforeConv) {
    const formattedDate = dateBeforeConv.format('DD MMMM yyyy');
    return `<div style="display: flex; justify-content: space-between; align-items: center"><span style="margin-right: 1rem;margin-left: 1rem">Date</span> <span style="font-weight: bold"></span> <span style="font-weight: bold">${formattedDate}</span></div>`;
  }
  return '';  
}
