<div id="forecastForm" class="container">
  <form *ngIf="inputForecastForm" [formGroup]="inputForecastForm" (ngSubmit)="forecastInput()">
    <div class="parameters">
      <div class="parameters-input gap-2 me-2" [ngClass]="{'parameters-input-column':accordionClicked}">
        <app-catalyst-parameters class="flex-grow-1 catalyst-parameters"></app-catalyst-parameters>
        <app-forecast-parameters class="flex-grow-1" [ngClass]="{'forecast-parameters':!accordionClicked,'forecast-parameters-opened':accordionClicked}" (isAccordionOpened)="isAccordionOpened($event)"></app-forecast-parameters>
      </div>
      <div class="parameters-action">
        <div class="import-btn">
          <app-ui-import-btn [options]="importExcelOption" (importedData)="onImport($event)"></app-ui-import-btn>
        </div>
        <div class="send-cta-container" [ngClass]="{run:(hasRequestInProgress$ | async) === true}">
          <div id="run-btn">
            <button id="send-inputs" type="submit" class="btn btn-primary flex-grow-0 btn-run"
            [disabled]="!inputForecastForm.valid || ((hasRequestInProgress$ | async) === true)">
            Run
            <span *ngIf="(hasRequestInProgress$ | async)" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
          </button>
          </div>
          <div *ngIf="(hasRequestInProgress$ | async)" id="btn-run-text">
            (20 - 40 sec)
          </div>
        </div>
      </div>
    </div>
  </form>

  <div *ngIf="predictionResults;else noData" id="data-container"
    class="d-flex flex-column gap-3">
    <app-cycle-length [cycleLengthInDays]="cycleLengthInDays" [startRunDate]="startRunDate" [cycleLengthDate]="cycleLengthDate"></app-cycle-length>
    
    <div class="graph-container">
      <div class="display-menu">
        <div (keydown)="changeToDisplay(1)" (click)="changeToDisplay(1)">
          <img src="./assets/images/one_screen.svg" alt="one_screen_display" [ngClass]="{active:graphNumberTodisplay!==1}">
          <img src="./assets/images/one_screen_red.svg" alt="one_screen_display_active" [ngClass]="{active:graphNumberTodisplay===1}">
        </div>
        <div (keydown)="changeToDisplay(2)" (click)="changeToDisplay(2)">
          <img src="./assets/images/two_screens.svg" alt="two_screen_display" [ngClass]="{active:graphNumberTodisplay!==2}">
          <img src="./assets/images/two_screens_red.svg" alt="two_screen_display_active" [ngClass]="{active:graphNumberTodisplay===2}">
        </div>
        <div (keydown)="changeToDisplay(4)" (click)="changeToDisplay(4)">
          <img src="./assets/images/four_screens.svg" alt="four_screen_display" [ngClass]="{active:graphNumberTodisplay!==4}">
          <img src="./assets/images/four_screens_red.svg" alt="four_screen_display_active" [ngClass]="{active:graphNumberTodisplay===4}">
        </div>
      </div>
      <div [ngClass]="{'simultaneous-display':simultaneousDisplay}">
        <ng-container *ngFor="let item of graphNumberTodisplay | numberToArray; let index=index">
          <app-forecast-graph class="simultaneous-graph-display" [startRunDate]="startRunDate" [predictionResults]="predictionResults" [index]="index"></app-forecast-graph>
        </ng-container>
      </div>
    </div>
    <app-details-parameters [forecastResults]="forecastResult" [periodDuration]="periodDuration"
      (cycleLengthDate)="getCycleLengthDate($event)" (startRunDate)="getStartRunDate($event)"></app-details-parameters>
  </div>

  <ng-template #noData>    
    <div class="d-flex flex-column gap-3">
      <div class="card">
        <div class="card-body align-self-center">
          Forecast data not available
        </div>       
      </div>
    </div>    
  </ng-template>

</div>
