import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { MsalGuard } from '@azure/msal-angular';
import { LoginComponent } from './login/login.component';
import { AuthenticationGuard } from './shared/guards/authentication.guard';
import { HomepageComponent } from './homepage/homepage.component';

const routes: Routes = [
  {
    path: 'login',
    component: LoginComponent,
  },
  {
    path: '',
    component: HomepageComponent,
    canActivate: [AuthenticationGuard, MsalGuard],
    children: [
      {
        path: ':refineryCode/forecast',
        loadChildren: () => import('./forecast/forecast-routing.module').then((m) => m.ForecastRoutingModule),
        data: {page: 'global'}
      },
      {
        path: ':refineryCode/user-forecast',
        loadChildren: () => import('./forecast/forecast-routing.module').then((m) => m.ForecastRoutingModule),
        data: {page: 'user'}
      },
    ],
  },
  {
    path: '**',
    redirectTo: 'login',
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
