<div class="card" *ngIf="predictionResults">
    <div class="card-body pb-0">
        <div class="w-25">
            <select class="select-forecast" id="forecastSelect" [(ngModel)]="selectedForecast" (change)="selectedGraph()">
                <option *ngFor="let data of predictionResults.forecastToDisplay; let index = index"  [ngValue]="data">
                    {{data.title}} ({{data.uom || '-'}})
                </option>
            </select>
        </div>
        <div id="forecast-to-display-{{selectedForecast.title}}">
            <app-ui-graph [xAxisTitle]="predictionResults.dos.title" [xValues]="predictionResults.dos.values"
                [startRunDate]="startRunDate"
                [forecastedValues]="selectedForecast.forecastedStates"
                [testRunsValues]="selectedForecast.testRuns"></app-ui-graph>
        </div>
    </div>
</div>
